import React, { PureComponent } from 'react';
import nookies from 'nookies';
import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';
import last from 'lodash/last';
import PropTypes from 'prop-types';
import qs from 'qs';
import { graphql } from 'react-apollo';
import styled, { css } from 'styled-components';
import { withRouter } from 'next/router';

import { GET_DOWNLOAD_LINK } from '/imports/generator/api/apollo/client/mutations';
import { isPaidUser, getRoute, isBlogDomain, freeAccessForYear, getDefaultLanguage } from '/lib/helpers';
import Link from '/components/Link';
import LinkButton from '/imports/core/ui/atoms/LinkButton';
import { withAccount } from '/imports/core/api/accounts/accountContext';
import { withIntl } from '/imports/core/api/useIntl';
import { withResponsiveContext } from '/imports/core/api/responsiveContext';
import { withTracking } from '/imports/core/hooks/useTracking';
import { isSkillsTagsWithMoreSpaceActive } from 'imports/generator/api/helpers';

@withTracking
@withResponsiveContext
@withAccount
@withRouter
@withIntl
@graphql(GET_DOWNLOAD_LINK)
class DownloadButton extends PureComponent {
  static propTypes = {
    source: PropTypes.object,
    isCoverLetter: PropTypes.bool,
    unorderedBulletPoint: PropTypes.string,
    orderedBulletPoint: PropTypes.string,
  };

  state = {
    href: '/checkout',
    isExternal: true,
    documentFormat: 'pdf',
  };

  componentDidUpdate(nextProps) {
    const { source } = this.props;
    const { source: nextSource } = nextProps;

    if (source !== nextSource) {
      this.updateDownloadLinkHandler();
    }
  }

  componentDidMount() {
    if (!this.props.source || !this.props.currentUser) return;

    this.updateDownloadLinkHandler();
  }

  updateDownloadLinkHandler = () => {
    const {
      currentUser: { role, gender, freeAccessUntil },
      source: {
        id,
        settings: { language },
      },
      isCoverLetter,
      locale,
      host,
    } = this.props;
    const [fileType] = this.state.documentFormat.split('/');
    const { agent_client_id } = nookies.get({});
    const paidUser = role === 'agent' || !!agent_client_id || isPaidUser(role) || freeAccessForYear(freeAccessUntil);
    if (paidUser) {
      const { origin } = window.location;

      const queryString = {
        type: fileType,
        onePage: true,
        redneg: gender,
        budapest_v2_tags_spacing: isSkillsTagsWithMoreSpaceActive(),
      };

      if (!isCoverLetter) {
        queryString.locale = locale || getDefaultLanguage(host);
      }

      const path = isCoverLetter ? 'cover-letter' : 'resume';
      this.setState({
        isExternal: false,
        href: `${origin}${getRoute(
          `/export/${path}/${id}?${qs.stringify(queryString)}${language === 'ar' ? '&isSwapable=true' : ''}`,
          locale,
          false,
          host,
          isBlogDomain(host),
        )}`,
      });
    }
  };

  clickHandler() {
    const {
      router: { asPath },
      source: {
        settings: { template, language, headingFont, contentFont, templateVersion },
      },
      isCoverLetter,
      trackEvent,
      currentUser: { role },
    } = this.props;
    const { fileType } = this.state;

    if (!isCoverLetter) {
      return trackEvent('download_cta', {
        context: last(asPath.split('/')),
        is_subscribed: isPaidUser(role),
        template: `${template}${templateVersion > 1 ? `-v${templateVersion}` : ''}`,
        resume_language: language,
        cv_format: fileType,
        resume_headingFont: headingFont,
        resume_contentFont: contentFont,
        unordered_bullet_point: this.props.unorderedBulletPoint,
        ordered_bullet_point: this.props.orderedBulletPoint,
      });
    }

    return trackEvent('cover_letter_download_cta', {
      template,
      format: fileType,
    });
  }

  render() {
    const {
      className,
      children,
      cta,
      router: { asPath },
      source: { id = '', __typename: sourceType } = {},
      preview,
      t,
      locale,
      documentHeight,
    } = this.props;

    const { isExternal } = this.state;
    let { href } = this.state;

    const queryString = {
      from: asPath,
      id,
      sourceType,
    };

    if (!isExternal && documentHeight) {
      href = `${href}&documentHeight=${documentHeight}`;
    }
    return (
      <ButtonCont className={className} cta={cta} preview={preview}>
        {isExternal ? (
          <Link as={href} href={`${href}?${qs.stringify(queryString)}&isSwapable=true`} passHref>
            <div>{children || t('download').toLocaleUpperCase(locale)}</div>
          </Link>
        ) : (
          <a href={href} onClick={this.clickHandler.bind(this)} target="_blank" rel="noreferrer">
            {children || t('download').toLocaleUpperCase(locale)}
          </a>
        )}
      </ButtonCont>
    );
  }
}

const ButtonCont = styled((p) => <LinkButton {...p} />)`
  padding: 0 !important;
  font-family: ${(p) => p.theme.font.family.correctAccent};
  background-color: ${(p) => p.theme.colors.cta};
  border-color: ${(p) => p.theme.colors.cta};
  text-transform: none;
  font-size: 18px;

  a {
    display: block;
    padding: 14px 24px;
    color: ${({ theme }) => theme.colors.white};
    line-height: 1;
    text-decoration: none;

    ${(p) =>
      p.cta &&
      css`
        padding: 0.9em 1.7em 0.7em;
      `}

    ${({ preview, theme }) =>
      preview &&
      css`
        font-family: ${theme.font.family.systemFont};
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0.35px;
        color: #f0efef;
        text-transform: uppercase;
      `}
  }
`;

export default DownloadButton;
